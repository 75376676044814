import React from "react"
import { ThemeProvider } from "styled-components"
import LayoutDefault from "../layouts/default"
import NotFoundBody from "../components/not-found-body"

const NotFoundPage = () => (
  <ThemeProvider theme={{ mode: "light" }}>
    <LayoutDefault>
      <NotFoundBody />
    </LayoutDefault>
  </ThemeProvider>
)

export default NotFoundPage
