import React, { useState, useEffect } from "react"
import AnimatedLink from "../animated-link"
import * as Styled from "./styled"

const NotFoundBody = () => {

  const [isDesktop, setIsDesktop] = useState(true)
  const [windowWidth, setWindowWidth] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)
  const [topPosition, setTopPosition] = useState(0)
  const [leftPosition, setLeftPosition] = useState(0)

  const movePupil = event => {
    if (isDesktop === true) {
      setTopPosition( (event.pageY * 100) / windowHeight)
      setLeftPosition( (event.pageX * 100) / windowWidth)
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1025) {
      setIsDesktop(false)
    }
    const setDimensions = () => {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }
    setDimensions()
    window.addEventListener("resize", () => {
      setDimensions()
    })
    return () => {
      window.removeEventListener("resize", () => {
        setDimensions()
      })
    }
  }, [])

  return (
    <Styled.Section
      onMouseMove={ movePupil }
      style={{ height: `${windowHeight}px` }}
    >

      <Styled.TextContainer>
        <h3>Error</h3>
        <h3>404</h3>
        <h3>Sorry!</h3>
        <AnimatedLink
          isInternalLink={ true }
          target="/"
          linkText="Go Back."
        />
      </Styled.TextContainer>

      <Styled.EyeContainer>
        <Styled.Eyes>
          <Styled.Eye>
            <Styled.PupilContainer>
              <Styled.Pupil
                style={
                  isDesktop ? (
                    {
                      top: `${topPosition}%`,
                      left: `${leftPosition}%`,
                    }
                  )
                  : null
                }
              />
            </Styled.PupilContainer>
          </Styled.Eye>
          <Styled.Eye>
            <Styled.PupilContainer>
              <Styled.Pupil
                style={
                  isDesktop ? (
                    {
                      top: `${topPosition}%`,
                      left: `${leftPosition}%`,
                    }
                  )
                  : null
                }
              />
            </Styled.PupilContainer>
          </Styled.Eye>
        </Styled.Eyes>
        <h3>Oups sorry, this page or project does not exist.</h3>
      </Styled.EyeContainer>

    </Styled.Section>
  )
}

export default NotFoundBody